<template>
	<div class="o-row o-row--lg">
		<div class="o-container">
			<div class="c-terms">
				<h2>Algemene voorwaarden</h2>

				<div class="c-terms__text">
					<h3>Algemeen</h3>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam voluptatem reprehenderit qui
						debitis, distinctio praesentium, aut consequuntur accusantium tenetur odio, nihil laborum
						repudiandae porro omnis necessitatibus est alias quos sed?
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./TermsAndConditions.controller.js"></script>
<style lang="scss" src="./TermsAndConditions.styles.scss"></style>
